//image size should be 1200 × 661
//test comment

//email templates
export const leftAlign =
  "https://i.ibb.co/dg8YVQ8/emaillayouts-left-Align.png";
export const leftAlignBig = "https://i.ibb.co/tXVrvbV/emaillayouts-left-Align.png";
export const salesEmail = "https://i.ibb.co/z47LY31/emaillayouts-sales.png";
export const centered =
  "https://imagizer.imageshack.com/img923/9704/p6gXsF.png";
export const topBanner =
  "https://imagizer.imageshack.com/img922/5938/Ds8jEb.png";

//vibes emails
export const vibesEmail = "https://i.ibb.co/mzvLC7W/vibes-Email-Layouts.png";
export const vibesEmailBig = "https://i.ibb.co/JkxfRPJ/vibes-Email-Layouts.png";

//infographic experience
export const tvVersion = "https://i.ibb.co/DDjgY3D/tv-goalsetting.png";
export const tvVersionBig = "https://i.ibb.co/7J0NQ7J/tv-goalsetting.png";

//motion reel
export const reelPreview = "https://i.ibb.co/XWsSs3w/reel-Preview.png";
export const keysInTheCar = "https://i.ibb.co/2WykNyN/keys-In-The-Car.png";
export const mrSquare = "https://i.ibb.co/ygnBJdr/mrSquare.png";
export const timeOff = "https://i.ibb.co/6HKnm0D/timeOff.png";
export const goalsTwo = "https://i.ibb.co/09CP1qD/goalsTwo.png";

//scrolling infographic
export const previewImg = "https://i.ibb.co/4Z8VSG0/preview-Img.png";

//the Center
export const dogPage = "https://i.ibb.co/cTGfYtG/dog-page.png";
export const indivPage = "https://i.ibb.co/Wy0273t/indiv-page.png";
export const indivPageBig = "https://i.ibb.co/6P8WGZB/indiv-page.png";

//voispark
export const boxUploader = "https://i.ibb.co/ZzXXnB2/box-Uploader-1.gif";
export const voisparkMain = "https://i.ibb.co/x5BT8JN/voisparkmain.png";
export const voiSparkMainBig = "https://i.ibb.co/2KC4YgT/voisparkmain.png";

//mSE
export const addScriptBtn = "https://i.ibb.co/2YFWf2C/add-Script-Btn-1.gif";
export const navigateTabs = "https://i.ibb.co/YBN1f0c/navigate-Tabs-1.gif";
export const preBuiltTool = "https://i.ibb.co/S7D9Dt5/pre-Built-Tool-1.gif";
export const mSEpreview = "https://i.ibb.co/LvtyJm4/m-SEpreview.png";
export const docking = "https://i.ibb.co/3FNjPyJ/docking-1.gif";

//decision making tool
export const dmInfoIcon = "https://i.ibb.co/n6QfQfW/dm-infoicon.png";
export const dmQuestion = "https://i.ibb.co/ZBG9Rfg/dm-question.png";
export const dmResult = "https://i.ibb.co/P5mzjyL/dm-result.png";
export const dmResultDetails = "https://i.ibb.co/kKKnTW9/dm-resultdetails.png";
export const dmStartPageBig = "https://i.ibb.co/QC5hqy4/dm-startpage.png";

//survey redesign
export const srAudioFeedbackBig = "https://i.ibb.co/xMzprXd/sr-Audio-Feedback.png";
export const srAudioFeedback = "https://i.ibb.co/cN2GskV/sr-Audio-Feedback.png";
export const srDatePicker = "https://i.ibb.co/LNtTh1Q/sr-Date-Picker-Desktop.png";
export const srLinkedSlider = "https://i.ibb.co/rH6wLmP/sr-Linked-Slider-Desktop.png";
export const srSemantic = "https://i.ibb.co/KKFJmMF/sr-Semantic-Differential.png";

