// import React from 'react';

//image size should be 1200 × 661

import { leftAlign, leftAlignBig, salesEmail, centered, topBanner } from "../img/projImages.js";

const emailTemplatesProject = {
  title: "Email Templates",
  shortTitle: "email",
  description:
    "Custom email templates created for branded corporate messaging.",
  heroImage: leftAlignBig,
  heroAlt: "Email template displayed on a PC, Mac, and iPhone.",
  previewImg: leftAlign,
  previewAlt: "Email template displayed on a PC, Mac, and iPhone.",
  skills: ["HTML5", "CSS3", "MJML"],
  introText: [
    {
      index: 0,
      text: "This is a small sampling of email templates produced for corporate customers to use as branded, consistently-formatted communications with their large employee bases.",
    },
    {
      index: 1,
      text: "We use MJML, a component-based library built in React, to customize and organize the templates. All templates are then tested with our internal system as well as Litmus. The previews shown on this page are for Outlook 2010 on Windows, Apple Mail 13 on Mac OSX, and Outlook on an Android 7.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: topBanner,
      alt: "Email template displayed on a PC, Mac, and iPhone.",
      text: "Our templates often start with a customer request, which my team scopes for practicality. There are several limitations to designing and writing emails, and we often propose tweaks to customers' designs in order to keep appearances consistent between different platforms.",
    },
    {
      index: 1,
      img: salesEmail,
      alt: "Email template displayed on a PC, Mac, and iPhone.",
      text: "The internal system used to distribute the emails to customers' large employee bases is also a consideration when designing email templates. Certain sections of the email can be customized in the platform, and must be designed with flexibility and responsiveness in mind.",
    },
    {
      index: 2,
      img: centered,
      alt: "Email template displayed on a PC, Mac, and iPhone.",
      text: "Our process is sped up by following MJML standards to create custom components. This frees up efforts to test designs on Litmus, and make changes to ensure consistency - or at least display of all elements - across all supported email platforms.",
    },
  ],
  optstyles: "dropshadow",
};

export default emailTemplatesProject;
