// import React from 'react';

//image size should be 1200 × 661

import { srAudioFeedbackBig, srAudioFeedback, srDatePicker, srLinkedSlider, srSemantic } from "../img/projImages.js";

const surveyRedesignProject = {
  title: "Survey Redesign",
  shortTitle: "surveyredesign",
  description:
    "Implementation of a modernized design for Tivian's survey technology.",
  heroImage: srAudioFeedbackBig,
  heroAlt: "Laptop computer and iPhone with screens open to an audio input survey question.",
  previewImg: srAudioFeedback,
  previewAlt: "Laptop computer and iPhone with screens open to an audio input survey question.",
  skills: ["CSS3", "SCSS", "PHP"],
  introText: [
    {
      index: 0,
      text: "Tivian has powerful survey technology, but the design hadn't been updated in some time. I worked with the Product team, Engineering, and a Lead Designer to implement a modernized, user-friendly update to survey question types.",
    },
    {
      index: 1,
      text: "This project required extensive use of CSS, SCSS, an understanding of the php backend, and Tivian's .tpl survey templates.",
    },
  ],
  imgsParas: [
    {
      index: 0,
      img: srSemantic,
      alt: "Laptop computer and iPhone with screens open to a multi-select semantic differential style survey question.",
      text: "Many question types have complex layouts, which required careful consideration of how to approach the responsive design aspects. We often had separate templates for mobile and desktop display of these more complex question types."
    },
    {
      index: 1,
      img: srDatePicker,
      alt: "Survey screen with a custom date picker open.",
      text: "For browsers that allow it, we created a custom date picker, with colors that could be configured to a client's branding."    
    },
    {
      index: 2,
      img: srLinkedSlider,
      alt: "Survey end screen with paragraphs of various aspects to keep in mind when selecting a medical plan type.",
      text: "The slider-type questions required some careful use of Flexbox to space elements in a responsive and user-friendly layout.",
    },
  ],
  optstyles: "dropshadow",
};

export default surveyRedesignProject;
